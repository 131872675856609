import { Box, Button, Container, Fade, Flex, Grid, GridItem, Heading, HStack, Spacer, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { FormikErrors, yupToFormErrors } from 'formik';
import React, { useRef } from 'react';
import * as Yup from 'yup';
import { ClaimType } from '../../../data-lib/data-model';
import { addressEquality, isValidAddress } from '../../../data-lib/ethereum';
import { emailAddressSchema } from '../../../hooks/useExtendedContacts';
import { fillToCount, inIframe } from '../../../tools/common';
import { ContinueButton, CreateSmallWhiteButton, EditButton } from '../../inputs/buttons';
import { ShadowBox } from '../../layout/cards';
import { claimAmountValidationSchema } from '../create-claim-modal/create-claim-inputs';
import { ImportRecipientsModal } from '../import-recipients-modal';
import { BullaItemRow } from './batch-rows';
import {
    BatchWizardState,
    ClaimConfigurationState,
    detailsWithDefaultValues,
    emptyInvoiceDetails,
    emptyPaymentDetails,
    InvoiceDetails,
    PaymentDetails,
} from './batch-state';
import { DeleteRecipientModal } from './delete-recipient-modal';
import { ContactSelectionModal, ImportFromPreviousBatchModal } from './import-modals';
import { BatchFooter, paymentDateSchema } from './shared-components';

const AddFromContactsButton = CreateSmallWhiteButton('Add from contacts');
const ImportFromCSVButton = CreateSmallWhiteButton('Import from CSV');
const ImportFromPreviousBatchButton = CreateSmallWhiteButton('Import from previous batch');
const AddRecipientManuallyButton = CreateSmallWhiteButton('Add recipient manually');

const getFieldName = (i: number) => (fieldName: string) => `[${i}].${fieldName}`;

const errorMessageSchema = (checkDueDate: boolean, userAddress: string) =>
    Yup.array()
        .required()
        .of(
            Yup.object().shape({
                dueDate: checkDueDate ? paymentDateSchema.required('Payment Date is required') : paymentDateSchema.optional(),
                amount: claimAmountValidationSchema,
                walletAddress: Yup.string()
                    .required('Wallet address required')
                    .test('is-valid-address', 'Invalid wallet address', value => isValidAddress(value || ''))
                    .test('is-own-wallet', 'Cannot use own wallet as recipient', value => !addressEquality(userAddress, value || '')),
                description: Yup.string(),
                emailAddress: emailAddressSchema,
            }),
        );

type OpenedClaimConfigurationCardProps = {
    state: ClaimConfigurationState;
    userAddress: string;
    setWizardState: React.Dispatch<React.SetStateAction<BatchWizardState>>;
    claimType: ClaimType;
    transactionPending: boolean;
    claimConfigurationOpenCloseDetailsOverride: 'Open' | 'Close';
    toggleClaimConfigurationOpenCloseDetails: VoidFunction;
};

export const OpenedClaimConfigurationCard: React.FC<OpenedClaimConfigurationCardProps> = ({
    state,
    userAddress,
    setWizardState,
    claimType,
    transactionPending,
    claimConfigurationOpenCloseDetailsOverride,
    toggleClaimConfigurationOpenCloseDetails,
}) => {
    const cardRef = useRef(null);
    const hasDueDate = !!state.defaultValues.defaultDueDate;
    const paymentDateColumnSize = hasDueDate ? '3fr ' : '';
    const claimGridColumns = `27px 3fr 5fr ${paymentDateColumnSize}3fr 60px 35px`;
    const columns = ['', 'NAME (OPTIONAL)', 'WALLET ADDRESS', hasDueDate ? 'PAYMENT DATE' : undefined, 'AMOUNT', '', ''].filter(
        (x): x is string => x !== undefined,
    );

    const schema = errorMessageSchema(hasDueDate, userAddress);
    const fillWithDefaultValues = detailsWithDefaultValues(state.defaultValues);

    const values = state.claimDetails.map(fillWithDefaultValues);
    const __errors = (): FormikErrors<Required<PaymentDetails> & { dueDate?: Date }[]> => {
        try {
            schema.validateSync(values.map(fillWithDefaultValues), {
                abortEarly: false,
            });
            return {};
        } catch (error: any) {
            if (error.name === 'ValidationError') {
                return yupToFormErrors(error);
            } else {
                console.error(error);
                return {};
            }
        }
    };
    const _errors = __errors();
    const errors = Array.isArray(_errors) ? fillToCount(_errors, values.length) : values.map((_, i) => _errors?.[i]);

    const setFieldValue =
        (i: number) =>
        (fieldName: keyof Required<InvoiceDetails>) =>
        (fieldValue: Required<InvoiceDetails>[keyof Required<InvoiceDetails>]) =>
            setWizardState(previousState => {
                const state = previousState as ClaimConfigurationState;

                return {
                    ...state,
                    claimDetails: state.claimDetails.map((details, index) =>
                        index == i ? { ...details, [fieldName]: fieldValue } : details,
                    ),
                };
            });

    const onDuplicate = (i: number) => () => {
        setWizardState(previousState => {
            const state = previousState as ClaimConfigurationState;
            const duplicatedClaim = { ...state.claimDetails[i] };
            return {
                ...state,
                claimDetails: [...state.claimDetails.slice(0, i + 1), duplicatedClaim, ...state.claimDetails.slice(i + 1)],
            };
        });
    };

    return (
        <Container maxW="container.xl" display="flex" flexDir="column" p="0">
            <ShadowBox p="0" mb="8" pb="4" display="flex" flexDir="column" underlyingBoxRef={cardRef}>
                <HStack p="4" alignItems="start">
                    <Heading color="heading" fontSize={18} whiteSpace="nowrap">
                        Add Recipients and Details
                    </Heading>
                    <Spacer />
                    <Wrap mt="0" justify={'flex-end'}>
                        <Fade in={!!state.claimDetails.length} unmountOnExit>
                            <WrapItem>
                                {CreateSmallWhiteButton(
                                    `${claimConfigurationOpenCloseDetailsOverride == 'Open' ? 'Close' : 'Open'} all details`,
                                )({ isDisabled: false, onClick: toggleClaimConfigurationOpenCloseDetails })}
                            </WrapItem>
                        </Fade>
                        <WrapItem key="manual">
                            <AddRecipientManuallyButton
                                onClick={() =>
                                    setWizardState({
                                        ...state,
                                        claimDetails: [hasDueDate ? emptyInvoiceDetails : emptyPaymentDetails, ...state.claimDetails],
                                    })
                                }
                                isDisabled={false}
                            />
                        </WrapItem>
                        <WrapItem key="contacts">
                            <ContactSelectionModal
                                onAdd={contacts => {
                                    const newDetails = contacts.map(
                                        (contact): InvoiceDetails => ({
                                            name: contact.name,
                                            walletAddress: contact.walletAddress,
                                            emailAddress: contact.emailAddress ?? '',
                                            confirmationEmailAddress: '',
                                        }),
                                    );
                                    setWizardState({ ...state, claimDetails: [...newDetails, ...state.claimDetails] });
                                }}
                                triggerElement={onOpen => <AddFromContactsButton onClick={onOpen} />}
                            />
                        </WrapItem>
                        <WrapItem key="batch">
                            <ImportFromPreviousBatchModal
                                claimType={claimType}
                                onAdd={paymentDetails =>
                                    setWizardState({ ...state, claimDetails: [...paymentDetails, ...state.claimDetails] })
                                }
                                triggerElement={onOpen => <ImportFromPreviousBatchButton onClick={onOpen} />}
                            />
                        </WrapItem>
                        <WrapItem key="csv">
                            <ImportRecipientsModal
                                triggerElement={onOpen => <ImportFromCSVButton onClick={onOpen} />}
                                save={recipients => {
                                    const newDetails = recipients.map(
                                        ({
                                            walletAddress,
                                            emailAddress,
                                            confirmationEmailAddress,
                                            name,
                                            amount,
                                            description,
                                            category: tag,
                                            token,
                                        }): PaymentDetails => ({
                                            walletAddress,
                                            emailAddress: emailAddress ?? '',
                                            confirmationEmailAddress: confirmationEmailAddress ?? '',
                                            name: name ?? '',
                                            amount,
                                            description,
                                            tags: !!tag ? [tag] : undefined,
                                            token: token?.token,
                                        }),
                                    );
                                    setWizardState({ ...state, claimDetails: [...newDetails, ...state.claimDetails] });
                                }}
                            />
                        </WrapItem>
                    </Wrap>
                </HStack>
                <Grid px="4" templateColumns={claimGridColumns} mt="2">
                    {columns.map((col, i) => (
                        <GridItem key={i} colSpan={1}>
                            <Text fontSize={12} fontWeight={700} opacity={0.5}>
                                {col}
                            </Text>
                        </GridItem>
                    ))}
                </Grid>
                <HStack p="0" direction="column">
                    <Box p="0">
                        {values.map((item, i) => {
                            return (
                                <DeleteRecipientModal
                                    key={`delete-${i}`}
                                    onDelete={() =>
                                        setWizardState({
                                            ...state,
                                            claimDetails: state.claimDetails.filter((_, thisI) => thisI !== i),
                                        })
                                    }
                                    triggerElement={onDelete => (
                                        <BullaItemRow
                                            key={i}
                                            columnString={claimGridColumns}
                                            error={errors[i]}
                                            touched={true}
                                            transactionPending={transactionPending}
                                            getQualifiedFieldName={getFieldName(i)}
                                            direction={claimType}
                                            hasDueDate={hasDueDate}
                                            setFieldValue={setFieldValue(i)}
                                            value={item}
                                            setStatus={_ => {}}
                                            deleteRow={onDelete}
                                            openCloseOverride={claimConfigurationOpenCloseDetailsOverride}
                                            onDuplicate={onDuplicate(i)}
                                            dropdownPortalRef={cardRef}
                                        />
                                    )}
                                />
                            );
                        })}
                    </Box>
                </HStack>
            </ShadowBox>

            <BatchFooter>
                <Button
                    colorScheme="white"
                    color="dark"
                    border="1px"
                    borderColor="dark"
                    px="8"
                    py="6"
                    onClick={() =>
                        setWizardState({
                            ...state,
                            kind: 'DefaultValueSelection',
                        })
                    }
                >
                    {'Back'}
                </Button>
                <Spacer />
                <ContinueButton
                    type="submit"
                    onClick={() =>
                        setWizardState({
                            ...state,
                            kind: 'ReviewState',
                            batchIndex: state.batchIndex ?? 0,
                            saveToContacts: state.saveToContacts ?? true,
                        })
                    }
                    isDisabled={!errors.every(x => x === undefined) || values.length == 0}
                />
            </BatchFooter>
        </Container>
    );
};

type ClosedClaimConfigurationCardProps = {
    batchIndex: number | undefined;
    setWizardState: React.Dispatch<React.SetStateAction<BatchWizardState>>;
};

export const ClosedClaimConfigurationCard = React.memo(({ batchIndex, setWizardState }: ClosedClaimConfigurationCardProps) => (
    <ShadowBox p="4" mb="4">
        <Flex color="#9F9F9F">
            <HStack mt="0">
                <Heading fontSize={18}>Add recipients and details</Heading>
            </HStack>
            <Spacer />
            <EditButton
                isDisabled={!!batchIndex && batchIndex !== 0}
                onClick={() =>
                    setWizardState(previousState => ({
                        ...(previousState as Omit<ClaimConfigurationState, 'kind'>),
                        kind: 'ClaimConfiguration',
                    }))
                }
            />
        </Flex>
    </ShadowBox>
));
