import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Button, Checkbox, Flex, Heading, HStack, Spacer, Stack, Tooltip } from '@chakra-ui/react';
import { Field, FieldProps, Form, Formik } from 'formik';
import React, { useRef } from 'react';
import * as Yup from 'yup';
import { ClaimType } from '../../../data-lib/data-model';
import { useAttachmentGenerationState } from '../../../hooks/useAttachmentGenerationState';
import { validate } from '../../../hooks/useExtendedContacts';
import { SignInAndOnboardAttachemntGeneration } from '../../../pages/settings/company-details/company-details-page';
import { useUIState } from '../../../state/ui-state';
import { apply } from '../../../tools/common';
import { ContinueButton, EditButton } from '../../inputs/buttons';
import { ShadowBox } from '../../layout/cards';
import { AccountTagField, ClaimAmountField, ClaimDescriptionField, DueByField } from '../create-claim-modal/create-claim-inputs';
import {
    BatchCreationState,
    BatchWizardState,
    DefaultBatchClaimValues,
    DefaultBatchValuesState,
    isBatchCreationState,
} from './batch-state';
import Width60Percent, { BatchFooter, BatchStepCard, paymentDateSchema } from './shared-components';

type OpenedDefaultValueSelectionCardProps = {
    state: DefaultBatchValuesState;
    claimType: ClaimType;
    setWizardState: React.Dispatch<React.SetStateAction<BatchWizardState>>;
};

const defaultValuesSchema = (checkDueDate: boolean) => {
    const description = { defaultDescription: Yup.string() };
    return Yup.object().shape(checkDueDate ? { ...description, defaultDueDate: paymentDateSchema.required() } : description);
};

export const OpenedDefaultValueSelectionCard = ({ state, setWizardState, claimType }: OpenedDefaultValueSelectionCardProps) => {
    const checkDueDate = !!state.defaultValues.defaultDueDate;
    const schema = defaultValuesSchema(checkDueDate);
    const { transactionPending } = useUIState();
    const { attachmentGenerationState, getCompanyDetails, setAttachmentGenerationState } = useAttachmentGenerationState();
    const isInitialValid = validate(() => schema.validateSync(state.defaultValues)) == true;
    const dropdownPortalRef = useRef<HTMLDivElement>(null);

    return (
        <Formik
            validateOnChange
            onSubmit={defaultValues =>
                setWizardState(state =>
                    isBatchCreationState(state)
                        ? {
                              ...state,
                              kind: 'ClaimConfiguration',
                              defaultValues,
                              claimDetails: state.claimDetails ?? [],
                              initialClaimDetails: state.claimDetails ?? [],
                          }
                        : state,
                )
            }
            initialValues={state.defaultValues}
            isInitialValid={isInitialValid}
            validationSchema={schema}
            validateOnMount
        >
            {({ isValid, status, touched, setFieldValue, values, errors, setStatus }) => (
                <Form style={{ width: '100%' }} placeholder="">
                    <BatchStepCard
                        title="Set default values"
                        subtitle="These values will apply to all the entries in your batch."
                        underlyingBoxRef={dropdownPortalRef}
                    >
                        <Stack spacing="4" h="100%" pt="4">
                            {state.defaultValues.defaultDueDate && (
                                <Field name="defaultDueDate">
                                    {({ field }: FieldProps) => (
                                        <DueByField
                                            {...{
                                                field,
                                                isDisabled: transactionPending,
                                                error: errors.defaultDueDate,
                                                touched: !!touched.defaultDueDate,
                                                setDueBy: apply(setFieldValue, 'defaultDueDate'),
                                                label: 'Default Payment Date',
                                                required: true,
                                            }}
                                        />
                                    )}
                                </Field>
                            )}
                            <Width60Percent>
                                <Field name="defaultDescription">
                                    {({ field }: FieldProps) => (
                                        <ClaimDescriptionField
                                            {...{
                                                field,
                                                isDisabled: transactionPending,
                                                error: errors.defaultDescription,
                                                touched: touched.defaultDescription,
                                                required: false,
                                                label: 'Default Description',
                                            }}
                                        />
                                    )}
                                </Field>
                            </Width60Percent>
                            <Width60Percent>
                                <Field name="defaultAmount">
                                    {({ field }: FieldProps) => (
                                        <ClaimAmountField
                                            {...{
                                                claimType: claimType,
                                                field,
                                                isDisabled: transactionPending,
                                                error: errors.defaultAmount,
                                                touched: touched.defaultAmount,
                                                setAmount: apply(setFieldValue, 'defaultAmount'),
                                                setToken: apply(setFieldValue, 'defaultToken'),
                                                amount: values.defaultAmount,
                                                token: values.defaultToken,
                                                required: false,
                                                label: 'Default Amount',
                                                disabledErrorLabels: true,
                                                disableBalanceLabels: true,
                                                includeNativeToken: claimType === 'Payment' && !state.defaultValues.defaultDueDate,
                                            }}
                                        />
                                    )}
                                </Field>
                            </Width60Percent>

                            <Width60Percent>
                                <Field name="defaultAccountTags">
                                    {({ field }: FieldProps) => (
                                        <AccountTagField
                                            {...{
                                                field,
                                                isDisabled: transactionPending,
                                                error: errors.defaultAccountTags,
                                                touched: touched.defaultAccountTags,
                                                setTags: apply(setFieldValue, field.name),
                                                setStatus,
                                                label: 'Default Categories',
                                                creatingExpense: false,
                                                dropdownPortalRef: dropdownPortalRef,
                                            }}
                                        />
                                    )}
                                </Field>
                            </Width60Percent>
                            <Width60Percent>
                                <Box py="2">
                                    <Field name="generatePDF" unmountOnExit>
                                        {({ field }: FieldProps) => (
                                            <HStack>
                                                <Checkbox
                                                    isDisabled={
                                                        transactionPending || (field.value !== true && attachmentGenerationState != 'ready')
                                                    }
                                                    isChecked={field.value}
                                                    onChange={e => setFieldValue('generatePDF', e.target.checked)}
                                                >
                                                    {claimType == 'Payment' ? 'Generate Payment Receipts' : 'Generate Invoices'}
                                                </Checkbox>
                                                <Tooltip
                                                    label={`Have Bulla auto-generate a PDF for each ${claimType.toLowerCase()}`}
                                                    placement="top-start"
                                                >
                                                    <InfoOutlineIcon />
                                                </Tooltip>
                                                <HStack>
                                                    {(attachmentGenerationState == 'no-details' ||
                                                        attachmentGenerationState == 'unauthenticated') && (
                                                        <SignInAndOnboardAttachemntGeneration
                                                            onSignIn={() =>
                                                                getCompanyDetails().then(details => {
                                                                    if (details) {
                                                                        setFieldValue('generatePDF', true);
                                                                        setAttachmentGenerationState('ready');
                                                                    } else {
                                                                        setAttachmentGenerationState('no-details');
                                                                    }
                                                                })
                                                            }
                                                            onDetailsReady={() => setFieldValue('generatePDF', true)}
                                                        />
                                                    )}
                                                </HStack>
                                            </HStack>
                                        )}
                                    </Field>
                                </Box>
                            </Width60Percent>
                        </Stack>
                    </BatchStepCard>

                    <BatchFooter>
                        {claimType == 'Payment' && (
                            <Button
                                colorScheme="white"
                                color="dark"
                                border="1px"
                                borderColor="dark"
                                px="8"
                                py="6"
                                onClick={() =>
                                    setWizardState({
                                        ...state,
                                        kind: 'SelectPaymentType',
                                    })
                                }
                            >
                                {'Back'}
                            </Button>
                        )}
                        <Spacer />
                        <ContinueButton type="submit" onClick={() => {}} isDisabled={!isValid || status === 'editing'} />
                    </BatchFooter>
                </Form>
            )}
        </Formik>
    );
};

type ClosedDefaultValueSelectionCardProps = {
    stateKind: BatchWizardState['kind'];
    batchIndex: number | undefined;
    setWizardState: React.Dispatch<React.SetStateAction<BatchWizardState>>;
};

export const ClosedDefaultValueSelectionCard = React.memo(
    ({ stateKind, batchIndex, setWizardState }: ClosedDefaultValueSelectionCardProps) => (
        <ShadowBox p="4" mb="4">
            <Flex color="#9F9F9F">
                <HStack mt="0">
                    <Heading fontSize={18}>Set Default Values</Heading>
                </HStack>
                <Spacer />
                <EditButton
                    isDisabled={stateKind && !!batchIndex && batchIndex !== 0}
                    onClick={() =>
                        setWizardState(previousState => ({
                            ...(previousState as BatchCreationState & { defaultValues: DefaultBatchClaimValues }),
                            kind: 'DefaultValueSelection',
                        }))
                    }
                />
            </Flex>
        </ShadowBox>
    ),
);
