import { useBoolean } from '@chakra-ui/react';
import { TransactionReceipt } from '@ethersproject/abstract-provider';
import { providers } from 'ethers';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { isEthersError, parseError, parseTxLogs } from '../data-lib/dto/parser';
import { TXStatus, waitForTransaction } from '../data-lib/networks';
import { useQuery } from '../hooks/useQuery';
import { handleErrorMessages, quickHash } from '../tools/common';
import { setSessionStorage, STORAGE_KEYS } from '../tools/storage';

export type TransactionHash = string;
export type PendingTxn = {
    hash?: TransactionHash;
    status: 'pending' | 'complete' | 'failed' | 'timeout' | 'archive';
    receipt?: TransactionReceipt;
    error?: string;
};
export type Alert = { message: string; link?: string; type: AlertTypes; deleteAlert: VoidFunction };
export type SetLoading = (isLoading: boolean) => void;
export type AddPendingTxn = (provider: providers.Provider, network: number, hash: TransactionHash) => Promise<void>;
export type UpdatePendingTxn = (pendingTxn: PendingTxn) => void;
export type AlertTypes = 'error' | 'success' | 'warning' | 'info';
export type UIState = {
    pendingTxns: Record<TransactionHash, PendingTxn>;
    addPendingTxn: AddPendingTxn;
    alerts: Record<string, Alert>;
    addAlert: ({ message, type, link }: { message: string; type?: AlertTypes; link?: string }) => VoidFunction;
    settingsOpen: boolean;
    setSettings: {
        readonly open: () => void;
        readonly close: () => void;
        readonly toggle: () => void;
    };
    transactionPending: boolean;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    addErrorMessage: (error: any, hash?: string) => void;
    archiveTx: (hash: string) => void;
};

export const openClaimParams = ['network', 'claim', 'instantPayment', 'loanOffer'];
export const openCreateClaimParams = [
    'network',
    'itemType',
    'recipient',
    'payer',
    'description',
    'name',
    'amount',
    'token',
    'offchainInvoiceId',
    'fromLink',
    'fromEmail',
];

export const openOffchainInvoiceParams = ['invoice'];

const onloadParams = [...new Set([...openClaimParams, ...openCreateClaimParams, ...openOffchainInvoiceParams])];

export const initUIState: UIState = {
    pendingTxns: {},
    addPendingTxn: async () => undefined,
    alerts: {},
    settingsOpen: false,
    setSettings: {
        open: () => undefined,
        close: () => undefined,
        toggle: () => undefined,
    },
    addAlert: () => () => undefined,
    transactionPending: false,
    isLoading: false,
    setIsLoading: () => undefined,
    addErrorMessage: () => undefined,
    archiveTx: () => {},
};

export const UIContext = React.createContext<UIState>(initUIState);

export const UIProvider = ({ children }: { children: React.ReactNode }) => {
    const [pendingTxns, setPendingTxns] = React.useState<Record<TransactionHash, PendingTxn>>({});
    const [alerts, setAlerts] = React.useState<Record<string, Alert>>({});
    const [settingsOpen, setSettingsOpen] = useBoolean();
    const setSettings = {
        open: setSettingsOpen.on,
        close: setSettingsOpen.off,
        toggle: setSettingsOpen.toggle,
    };
    const [isLoading, setIsLoading] = React.useState(false);
    const capturedParams = useQuery(onloadParams);
    const { pathname } = useLocation();
    const userLoadedWithParams = !!capturedParams.network && Object.values(capturedParams).length > 1;
    const userDirectedToUpgrade = pathname.includes('upgrade');
    const [errorNonce, setErrorNonce] = React.useState(0);

    /**
     * @returns a cleanup function that removes the alert
     */
    const addAlert = useCallback(({ link, message, type = 'info' }: { message: string; type?: AlertTypes; link?: string }) => {
        const key = Date.now() + message;
        const deleteAlert = () => {
            setAlerts(txns => {
                const { [key]: _, ...rest } = txns;
                return rest;
            });
        };
        setAlerts(alerts => ({ ...alerts, [key]: { message, type, link, deleteAlert } }));
        return deleteAlert;
    }, []);

    const addPendingTxn = useCallback(async (provider: providers.Provider, network: number, hash: TransactionHash) => {
        setPendingTxns(txns => ({ ...txns, [hash]: { hash, status: 'pending' } }));
        console.debug('awaiting tx: ', hash);
        try {
            const tx = await waitForTransaction(network, provider, hash);
            if (tx.status !== undefined && tx.status === TXStatus.FAILED) throw new Error('Execution reverted');
            const receipt = {
                ...tx,
                events: parseTxLogs(tx.logs),
            };
            setPendingTxns(pendingTxns => ({ ...pendingTxns, [hash]: { ...pendingTxns[hash], status: 'complete', receipt } }));
        } catch (e: any) {
            addErrorMessage(e, hash);
        }
    }, []);

    const archiveTx = (id: string) => setPendingTxns(pendingTxns => ({ ...pendingTxns, [id]: { ...pendingTxns[id], status: 'archive' } }));

    const addErrorMessage = useCallback(
        (error: any, hash: string = quickHash(error)) => {
            const messageString: string =
                isEthersError(error) && parseError(error) !== undefined
                    ? parseError(error)
                    : !!error.message && typeof error.message === 'string'
                    ? error.message
                    : error.data && typeof error.data === 'string'
                    ? error.data
                    : typeof error === 'string'
                    ? error
                    : 'Error';
            const parsedMessage = handleErrorMessages(messageString);
            const hashWithNonce = `${hash}${errorNonce}`;
            if (!parsedMessage.toLowerCase().includes('user denied transaction signature')) {
                setPendingTxns(txns => ({
                    ...txns,
                    [hashWithNonce]: { status: 'failed', error: parsedMessage, hash },
                }));
                setErrorNonce(currentNonce => currentNonce + 1);
            }
        },
        [errorNonce],
    );

    const transactionPending = !!Object.values(pendingTxns).find(txn => txn.status === 'pending');
    const context = useMemo(
        () => ({
            isLoading,
            setIsLoading,
            transactionPending,
            alerts,
            pendingTxns,
            addAlert,
            addErrorMessage,
            addPendingTxn,
            settingsOpen,
            setSettings,
            archiveTx,
        }),
        [pendingTxns, alerts, settingsOpen, transactionPending, isLoading, errorNonce],
    );

    useEffect(() => {
        if (userLoadedWithParams) {
            setSessionStorage(STORAGE_KEYS.capturedParams, capturedParams);
        }

        if (userDirectedToUpgrade) {
            setSessionStorage(STORAGE_KEYS.capturedParams, { upgrade: true });
        }
    }, []);

    return <UIContext.Provider value={context}>{children}</UIContext.Provider>;
};

export const useUIState = () => {
    const context = React.useContext(UIContext);
    if (context === undefined) throw new Error('useUIState must me used within the UI provider');
    return context;
};
